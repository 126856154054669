<template>
  <dash-page-new
      :title="$store.state.settings.pageData.title || $t('Structure')"
      :root="rootName"
      :subtitle="selectedEntityName || $t('Structure')"
      :root-location="rootLocation"
      icon="mdi-sitemap-outline"
      :side-navigation="navigationFiltered"
      :navigation-route="navigationRoute"
      @root="handleReturnAction"
      :navigation-return-route="returnRoute"
  >
<!--    :navigation-return-route=""-->
    <template #header_action>

      <div class="d-flex align-center">

        <ft-select
            v-model="$store.state.wsu_structure.selectedLang"
            :items="langsSelect"
        >
          <template #default="{text}">
            <v-hover #default="{hover}">
              <v-sheet
                  :color="hover ? wsLIGHTCARD : null"
                  class="mr-3 px-1 pl-3 d-flex align-center wsRoundedLight"
                  style="transition: all 0.2s ease"
                  height="40"
                  v-ripple
              >
                <h4 >{{ text }}
                  <v-icon>mdi-menu-down</v-icon>
                </h4>
              </v-sheet>
            </v-hover>


          </template>

        </ft-select>

        <portal-target name="structure_header_action">
          <ft-select
              v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
              :items="$store.state.settings.pageData.headerDropdownActions || []">
            <ws-button
                @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
                :label-raw="$store.state.settings.pageData.headerActionText"
                :click-stop="!$store.state.settings.pageData.headerDropdownActions"
                :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
            />
          </ft-select>
        </portal-target>


      </div>


    </template>

    <template #default>


      <description
          v-if="getComponentCondition('description')"
          :entity-type="root"
          :uuid="uuid"
      />
      <institutes
          v-else-if="getComponentCondition('institutes') || !location "
      />
      <faculties
          v-else-if="getComponentCondition('faculties')"
      />
      <departments
          v-else-if="getComponentCondition('departments')"
      />
      <specialties
          v-else-if="getComponentCondition('specialties')"
      />
      <disciplines
          v-else-if="getComponentCondition('disciplines')"
      />
      <teachers
          v-else-if="getComponentCondition('teachers')"
      />
      <workload
          v-else-if="getComponentCondition('workload')"
      />
      <groups
          v-else-if="getComponentCondition('groups')"
      />
      <div v-else>
        Page not Found
      </div>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions, mapState} from "vuex";
import description from "@modules/wsu_structure/components/description/description.vue";
import institutes from "@modules/wsu_structure/components/institutes.vue";
import faculties from "@modules/wsu_structure/components/faculties.vue";
import departments from "@modules/wsu_structure/components/departments.vue";
import specialties from "@modules/wsu_structure/components/specialties.vue";
import disciplines from "@modules/wsu_structure/components/disciplines.vue";
import teachers from "@modules/wsu_structure/components/teachers.vue";
import workload from "@modules/wsu_structure/components/workload/workload.vue";
import groups from "@modules/wsu_structure/components/groups.vue";

export default {
  name: "DashStructure",
  components : {
    description,
    institutes,
    faculties,
    departments,
    specialties,
    disciplines,
    teachers,
    workload,
    groups,
  },
  props : {
    location : {
      type : String,
    },
    root : {
      type : String,
    },
    uuid : {
      type : String,
    }
  },

// { path: "structure/main/:location" ,  component: () => import("@/modules/wsu_structure/components/DashStructure.vue") , props : true},
// { path: "structure/:root/:entityType/:uuid/location?" ,  component: () => import("@/modules/wsu_structure/components/DashStructure.vue") , props : true},

  data() {
    return {
      items : [
        { name : 'Information technologies faculty' }
      ],
    }
  },
  computed : {
    ...mapState('wsu_structure' , [
        'selectedEntity',
      'selectedEntityType' ,
      'selectedEntityId',
      'selectedEntityName',
      'returnEntityType',
      'returnEntityId',
      'returnEntityName',
    ]),
    ...mapState('dashboard' , [
      'pageNavigationReturnPressTrigger' ,
    ]),
    rootName() {
      // selectedEntityType ? $t(PLURIZE_WORD(CAPITALIZA_FIRST_CHAR(selectedEntityType))) : $store.state.settings.pageData.root || $t('Dashboard')
      return this.$t('Dashboard2')
    },
    rootLocation() {
      return this.businessDashLink('')
    },
    navigationRoute() {
      if (this.root) {
        return `structure/${this.root}/${this.uuid}`
      }
      return 'structure/main'
    },

    returnRoute() {
      if (this.root) {
        return 'structure/main'
      }
      return ''

      //
      // let route = ''
      // if ( this.returnEntityType.length > 0 ) {
      //   let entity =  this.returnEntityType[this.returnEntityType.length - 1]
      //   route = 'structure'
      //   if ( entity ) {
      //     route += '/' + this.PLURIZE_WORD(entity)
      //   }
      // }
      // return route
    },
    langsSelect() {
      return [
        { text : 'UA' , value : 'ua' },
        { text : 'EN' , value : 'en' }
      ]
    },
    navigationFiltered() {
      let items = this.navigation

      items = items.filter( el =>
          (el.requireEntity && !!this.selectedEntityType) ||
          (el.entity && !this.returnEntityType.includes(el.entity) && this.getEntityHierarcyIndex(el.entity) > this.getEntityHierarcyIndex(this.selectedEntityType)  ) ||
          (el.parent && el.parent.includes(this.selectedEntityType) )
      )

      return items
    },
    navigation() {
      return  [
        // { text : this.$t('Rectorate')      ,  value : 'rectorate'       , path : 'editor'      , icon : 'mdi-account-tie' ,  permission:'MODULE_WESTUDY__EDITOR', },

        // { text : this.$t('ScienceCouncil') ,  value : 'science_council' , path : 'editor'      , icon : 'mdi-school' ,  permission:'MODULE_WESTUDY__EDITOR', },

        // { text : this.$t('Description') ,
        //   value : 'description'      ,
        //   path : 'description'    ,
        //   icon : 'mdi-table-of-contents' ,
        //   permission:'MODULE_WESTUDY__EDITOR',
        //   requireEntity : true
        // },
        { text : this.$t('Institutes')     ,
          value : 'editor'         ,
          path : 'institutes'    ,
          icon : 'mdi-table-of-contents' ,
          // permission:'MODULE_WSU-STRUCTURE',
          entity : 'institute'
        },
        { text : this.$t('Faculties')      ,
          value : 'faculties'      ,
          path : 'faculties'     ,
          icon : 'mdi-image-text'        ,
          // permission:'MODULE_WSU-STRUCTURE',
          entity : 'faculty'
        },
        { text : this.$t('Departments')    ,
          value : 'departments'    ,
          path : 'departments'   ,
          icon : 'mdi-account-multiple'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          entity : 'department'
        },
        { text : this.$t('EducationPrograms')    ,
          value : 'specialties'    ,
          path : 'specialties'   ,
          icon : 'mdi-school-outline'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          entity : 'specialty'
        },
        { text : this.$t('Description') ,
          value : 'description'    ,
          path : 'description'   ,
          icon : 'mdi-image-text'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          parent : ['specialty']
        },
        { text : this.$t('Disciplines') ,
          value : 'disciplines'    ,
          path : 'disciplines'   ,
          icon : 'mdi-school-outline'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          parent : ['specialty' , 'department']
        },
        { text : this.$t('Teachers')    ,
          value : 'teachers'    ,
          path : 'teachers'   ,
          icon : 'mdi-account-tie'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          parent : ['department']
        },
        // { text : this.$t('Workload')    ,
        //   value : 'workload'    ,
        //   path : 'workload'   ,
        //   icon : 'mdi-timetable'  ,
        //   permission:'MODULE_WSU-STRUCTURE',
        //   parent : ['department']
        // },
        { text : this.$t('Groups') ,
          value : 'groups'    ,
          path : 'groups'   ,
          icon : 'mdi-school-outline'  ,
          // permission:'MODULE_WSU-STRUCTURE',
          parent : ['specialty']
        },
      ]
    },
    entitiesHierarcyArray() {
      return this.navigation.filter(el => el.entity ).map(el => el.entity)
    },

    headers() {
      return [
        { text : this.$t('Name') , value : 'name' }
      ]
    }
  },
  watch : {
    pageNavigationReturnPressTrigger() {
      this.handleReturnAction()
    },
    root() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('wsu_structure' , [
        "GET_STRUCTURE_ENTITY"
    ]),

    getComponentCondition(component) {
      return component === this.location
    },
    getEntityHierarcyIndex(type) {
      return this.entitiesHierarcyArray.findIndex(el => el === type)
    },
    handleReturnAction() {
      this.$store.state.wsu_structure.selectedEntityType = this.$store.state.wsu_structure.returnEntityType[this.$store.state.wsu_structure.returnEntityType.length - 1] || null
      this.$store.state.wsu_structure.selectedEntityId = this.$store.state.wsu_structure.returnEntityId[this.$store.state.wsu_structure.returnEntityId.length - 1] || null
      this.$store.state.wsu_structure.selectedEntityName = this.$store.state.wsu_structure.returnEntityName[this.$store.state.wsu_structure.returnEntityName.length - 1] || null

      this.$store.state.wsu_structure.returnEntityType.pop()
      this.$store.state.wsu_structure.returnEntityId.pop()
      this.$store.state.wsu_structure.returnEntityName.pop()

    },
    async initPage() {
      this.$store.state.wsu_structure.selectedEntity = {}
      if (!this.root) {
        this.$store.state.wsu_structure.selectedEntityType = null
        this.$store.state.wsu_structure.selectedEntityId = null
        this.$store.state.wsu_structure.selectedEntityName = null
      } else {
        this.$store.state.wsu_structure.selectedEntityType = this.root
        this.$store.state.wsu_structure.selectedEntityId = this.uuid
      }

      if (this.uuid) {
        this.$store.state.wsu_structure.selectedEntity = await this.GET_STRUCTURE_ENTITY({
          type : this.root,
          uuid : this.uuid
        })
      }
    }
  },
  beforeMount() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.wsu_structure.selectedEntityType = null
    this.$store.state.wsu_structure.selectedEntityId = null
    this.$store.state.wsu_structure.selectedEntityName = null
    this.$store.state.wsu_structure.selectedEntity = {}
  }
}
</script>

<style scoped>

</style>