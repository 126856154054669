<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Groups')"
      :subtitle="$t('Structure')"
      icon="mdi-account-multiple"
      :no-content-condition="false"
      :no-content-text="$t('wsu_structure.group.no_content')"
      :header-action-text="$t('Actions')"
      :header-dropdown-actions="dropdownActionsSelect"


      fill-height
  >

    <template #default >
      <ws-data-table
          :items="itemsFiltered"
          :row-action="selectEntity"
          :headers="headers"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          class="mt-5"
      >

        <template #expand="{item,index}">
          <tr v-for="(child,j) in  item.children" :key="'expand' + index + j "
              @click.prevent="selectEntity(child)"
              @contextmenu="openContextMenu($event, child)"
              class="pointer"
              :style="`background-color: ${wsLIGHTACCENT + '44'}`"
          >
            <td>
              <h5 class="text-no-wrap" :style="`color : ${wsACCENT}`">{{ child.name }}</h5>
            </td>
            <td>
              <h5 v-if="child.study_plan_name" >{{ child.study_plan_name }}</h5>
              <h5 v-else :style="`color : ${wsDARKLIGHT}`" >{{ $t('wsu.education.study_plan.choose') }}</h5>
            </td>
            <td>
              <h5 :style="!child.students_count ? `color : ${wsDARKLIGHT}` : null"  class="text-no-wrap text-center" >
                <v-icon v-if="child.students_count" :color="wsDARKLIGHT">mdi-account-school</v-icon>
                {{ child.students_count || $t('None') }}
              </h5>
            </td>
            <td>
              <h5 v-if="child.year" class="text-no-wrap" >{{ child.year }} {{ $t('Year') }}</h5>
            </td>
            <td>
              <h5 class="text-no-wrap" >{{ child.degree_name }} </h5>
            </td>
          </tr>
        </template>

        <template #item.name="{item}">
          <h5 class="text-no-wrap" :style="`color : ${wsACCENT}`">{{ item.name }} <span v-if="item.last_name"> - {{ item.last_name }}</span></h5>
        </template>

        <template #item.study_plan_name="{item}">
          <h5 v-if="item.is_group_set">{{ $t('GroupSet') }}</h5>
          <h5 v-else-if="item.study_plan_name" >{{ item.study_plan_name }}</h5>
          <h5 v-else :style="`color : ${wsDARKLIGHT}`" >{{ $t('wsu.education.study_plan.choose') }}</h5>
        </template>

        <template #item.year="{item}">
          <h5 v-if="item.year" class="text-no-wrap" >{{ item.year }} <span v-if="item.last_year"> - {{ item.last_year }}</span> {{ $t('Year') }}</h5>
        </template>

        <template #item.students_count="{item}">
          <div>
            <h5 :style="!item.students_count ? `color : ${wsDARKLIGHT}` : null"  class="text-no-wrap text-center" >
              <v-icon v-if="item.students_count" :color="wsDARKLIGHT">mdi-account-school</v-icon>
              {{ item.students_count || $t('None') }}
            </h5>
          </div>

        </template>

        <template #item.degree_name="{item}">
          <h5 class="text-no-wrap" >{{ item.degree_name }} </h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <!--  Add Edit Group Set -->
      <ws-dialog
          v-model="displayGroupSetDialog"
          :title="$t('wsu_structure.group.category.add')"
          @save="addGroupSet"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
      >

        <ws-text-field
            class="mt-6"
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('wsu_structure.group.category.name_placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

        <ws-text-field
            class="mt-6"
            v-model="entityData.years"
            number
            :label="$t('wsu_structure.years_of_study.title')"
            :placeholder="$t('wsu_structure.years_of_study.placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

        <div v-if="entityData.years">

          <ws-text-field
              v-for="year in parseInt(entityData.years)" :key="year"
              class="mt-6"
              v-model="entityData[`group_name_${year}`]"
              :label="$t('Name')"
              :placeholder="$t('wsu_structure.group.category.name_placeholder')"
              :error="nameError"
              @input="nameError = false"
          />

        </div>


      </ws-dialog>

      <!--  Add Edit Group -->
      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editEntity ? 'wsu_structure.group.add' : 'wsu_structure.group.edit')"
          @save="addEntity"
          @delete="deleteEntity"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          :show-delete="editEntity"
      >

        <ws-text-field
            class="mt-6"
            v-model="entityData.name"
            :label="$t('wsu_structure.group.name.title')"
            :placeholder="$t('wsu_structure.group.name.placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

        <ws-select
            class="mt-6 mb-6"
            v-model="entityData.degree_id"
            @input="entityData.study_plan_id = null; entityData = COPY(entityData)"
            :items="degreesSelect"
            :label="$t('Degree')"
            :placeholder="$t('wsu_structure.degree.choose')"
            clearable
            autocomplete
        />

        <ws-select
            class="mt-6 mb-6"
            v-model="entityData.study_plan_id"
            :items="studyPlansSelectFiltered"
            :label="$t('StudyPlan')"
            :placeholder="$t('wsu.education.study_plan.choose')"
            clearable
            autocomplete
        />

        <v-row no-gutters>
          <v-col :cols="nextYearGroupSelect(entityData).length > 0 ? 6 : 12" class="pr-2">
            <ws-select
                class="mt-6 mb-6"
                v-model="entityData.year"
                :items="yearsSelect"
                :label="$t('wsu_structure.year_of_study.title')"
                :placeholder="$t('wsu_structure.year_of_study.chose')"
                :tooltip="$t('wsu_structure.year_of_study.tooltip')"
                clearable
                autocomplete
            />
          </v-col>
          <v-col v-if="nextYearGroupSelect(entityData).length > 0" cols="6" class="pl-2">
            <ws-select
                class="mt-6 mb-6"
                v-model="entityData.next_group_id"
                :items="nextYearGroupSelect(entityData)"
                :label="$t('wsu_structure.group.next_year_group.title')"
                :placeholder="$t('wsu_structure.group.next_year_group.choose')"
                :tooltip="$t('wsu_structure.group.next_year_group.tooltip')"
                clearable
                autocomplete
            />
          </v-col>
        </v-row>




      </ws-dialog>
      <!-- Students Dialog-->
      <ws-group-students-dialog
          v-if="displayStudentsDialog"
          v-model="displayStudentsDialog"
          :uuid="selectedGroupId"
          :groups-select="groupsSelect"
          @students-count-update="editStudentsCount"
      />

      <!--  Delete Faculty-->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteEntity"
          :title="$t('wsu_structure.group.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

      <!-- Context for Child-->
      <ft-select
          v-if="displayContextMenu"
          :items="contextActionsSelect"
          :expanded="displayContextMenu"
          @expand="displayContextMenu = $event"
          :x="x"
          :y="y"
          absolute
      />

    </template>

  </sub-page>
</template>

<script>
import wsGroupStudentsDialog from "@/modules/wsu_structure/components/groups/wsGroupStudentsDialog";
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  components : {
    wsGroupStudentsDialog
  },
  data() {
    return {
      items : [],
      studyPlansSelect : [],
      degreesSelect : [],
      displayDialog : false,
      entityData : {},
      selectedItem : {},
      displayDeleteDialog : false,
      editEntity : false,
      nameError : false,
      selectedGroupId : null,
      displayStudentsDialog : false,
      displayGroupSetDialog : false,
      displayContextMenu : false,
      x : null,
      y : null,
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),

    itemsFiltered() {
      let items = []
      let rootGroups = this.COPY(this.items).filter(el=> el.year === 1 && !!el.next_group_id)
      let rootGroupsItemObject = {}
      let excludeArray = []

      let findNext = (group, rootUuid) => {
        let next = null
        if ( group.next_group_id ) {
          next = this.items.find(el => el.uuid === group.next_group_id )
          if ( next ) {
            excludeArray.push(next.uuid)
            rootGroupsItemObject[rootUuid].children.push(next)
            rootGroupsItemObject[rootUuid].last_name = next.name
            rootGroupsItemObject[rootUuid].last_year = next.year
            rootGroupsItemObject[rootUuid].students_count += next.students_count
            findNext(next , rootUuid)
          }
        }
      }

      rootGroups.forEach(rootGroup => {
        rootGroupsItemObject[rootGroup.uuid] = rootGroup
        rootGroupsItemObject[rootGroup.uuid].children = [this.COPY(rootGroup)]

        rootGroupsItemObject[rootGroup.uuid].is_group_set = true
        rootGroupsItemObject[rootGroup.uuid].prevent_context = true

        rootGroupsItemObject[rootGroup.uuid].expanded = false
        excludeArray.push(rootGroup.uuid)
        findNext(rootGroup , rootGroup.uuid)
      })

      Object.keys(rootGroupsItemObject).forEach(uuid => {
        items.push(rootGroupsItemObject[uuid])
      })

      let freeGroups = this.items.filter(el => !excludeArray.includes(el.uuid))

      return [...items,...freeGroups]

    },

    dropdownActionsSelect() {
      return [
        // { text : this.$t('wsu_structure.group.category.add') , value : 'add_category' , action : this.openNewGroupSet },
        { text : this.$t('wsu_structure.group.add') , value : 'add' , action : this.openNewEntity },
        // { text : this.$t('wsu_structure.group.open_next_year_action') , value : 'next_year' , action : this.openNextYearAction },
      ]
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' , width: 40 },
        { text : this.$t('StudyPlan') , value : 'study_plan_name' ,  },
        { text : this.$t('Students') , value : 'students_count' , width: 40 },
        { text : this.$t('Year') , value : 'year' , width : 40},
        { text : this.$t('Degree') , value : 'degree_name' , width : 40 },

      ]
    },

    studyPlansSelectFiltered() {
      let items  = this.studyPlansSelect
      if ( this.entityData.degree_id ) {
        items = items.filter(el => el.degree_id === this.entityData.degree_id)
      }
      return items
    },
    yearsSelect() {
      let items = []
      if ( this.entityData.study_plan_id) {
        let plan = this.studyPlansSelect.find(el=>el.value === this.entityData.study_plan_id)
        if ( plan && plan.semesters_per_year ) {

          for (let i = 1; i<= Math.ceil(plan.semesters/plan.semesters_per_year); i++ ) {
            items.push({ text : i , value : i })
          }
        }
      }
      return items
    },
    specializationsSelectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      if ( !this.entityData.specialty_id) {
        return []
      }

      items = items.filter(el => el.specialty_id === this.entityData.specialty_id )


      return items
    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditEntity() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
    specializationsSlectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      return items
    },
    groupsSelect() {
      let items = []

      this.items.forEach(item => {
        items.push({
          text : item.name,
          value : item.uuid
        })
      })

      return items
    }

  },
  methods : {
    ...mapActions('wsu_structure' , [
        'GET_ACADEMIC_GROUPS' ,
        'ADD_EDIT_ACADEMIC_GROUP' ,
        'DELETE_ACADEMIC_GROUP'
    ]),

    editStudentsCount(value) {

      let index = this.items.findIndex(el => el.uuid === this.selectedGroupId )
      if ( index === -1 ) {
        return
      }
      this.items[index].students_count += value
      this.items = this.COPY(this.items)

    },
    openContextMenu($event , item) {

      this.selectedItem = item
      this.$emit('select-item' , item)
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })

      if ( this.contextActionsSelect.length > 0  ) {
        this.displayContextMenu = true
      }
    },

    getNextGroup(uuid) {
      if ( !uuid ) {
        return false
      }
      return this.items.find(el => el.uuid === uuid )
    },
    nextYearGroupSelect(group) {

      let items = []
      if ( !group ) {
        return []
      }
      if ( !group.study_plan_id ) {
        return []
      }
      if ( !group.year ) {
        return []
      }

      let currentGroupYears = this.getGroupStudyPlanYears(group)
      let groupDegree = group.degree_id
      let groupYear = group.year

      this.items.forEach(el=> {
        if ( (this.getGroupStudyPlanYears(el) === currentGroupYears &&
            groupDegree === el.degree_id &&
            groupYear + 1 === el.year &&
            !el.previous_group_id) || el.uuid === group.next_group_id
        ) {
          items.push({
            text : el.name,
            value : el.uuid
          })
        }
      })
      return items
    },

    getGroupStudyPlanYears(group) {
      if ( group.study_plan_id) {
        let plan = this.studyPlansSelect.find(el=>el.value === group.study_plan_id)
        if ( plan && plan.semesters_per_year ) {
          return Math.ceil(plan.semesters/plan.semesters_per_year)
        }
      }
      return 0
    },

    openNewGroupSet() {
      this.entityData = {}
      this.displayGroupSetDialog = true
    },

    selectEntity(item) {
      if (item.is_group_set) {
        item.expanded = !item.expanded
        return
      }
      if ( !item.study_plan_id ) {
        this.selectedItem = this.COPY(item)
        this.openEditEntity(item)
        return
      }
      this.viewGroupStudents(item)

    },
    // AJAX
    viewGroupStudents(item) {
      this.selectedGroupId = item.uuid
      this.displayStudentsDialog = true
    },

    async addEntity() {

      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('wsu_structure.group.name.error') , 'warning')
      }

      this.entityData.lang = this.selectedLang
      this.entityData.university_specialty_id = this.$store.state.wsu_structure.selectedEntityId
      let result = await this.ADD_EDIT_ACADEMIC_GROUP(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError') , 'error')
        return
      }


      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu_structure.group.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }

      this.displayDialog = false
    },
    async deleteEntity() {
      let result = await this.DELETE_ACADEMIC_GROUP(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu_structure.group.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    // Methods
    openEditEntity() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },
    openNewEntity() {
      this.editEntity = false
      this.entityData = {}
      this.displayDialog = true
    },
    // Technical
    async initPage() {
      let result = await this.GET_ACADEMIC_GROUPS(this.$store.state.wsu_structure.selectedEntityId)
      this.items = result.academic_groups || []
      this.studyPlansSelect = result.study_plans_select || []
      this.degreesSelect = result.degrees_select || []


    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>