<template>
  <ws-dialog
      v-model="display"
      no-navigation
      width="800"
      height="90%"
      max-height="620px"
      no-padding
      not-centered
  >

    <template #header>
      <div class="d-flex justify-space-between align-center pb-3 px-6 pt-5" >
        <div>
          <h3 :style="`color : ${wsDARKER}; font-weight: 600; font-size: ${!SM ? 20 : 16}px`">
            {{  $t('wsu_structure.group.students.dialog_title') + ': ' + entity.name  }}
          </h3>
<!--          <h5 class="font-weight-regular">-->
<!--            {{ entity.study_plan_name }} - -->
<!--              {{ entity.degree_name }}, {{ entity.year }} {{ $t('Year') }}-->
<!--          </h5>-->
        </div>
        <v-btn
            @click="display = false"
            class="mr-n2"
            :color="wsDARKER"
            icon
        >
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="d-flex">
        <ws-navigation-header
            v-model="navigation"
            :items="navigationSelect"
            no-number
            padding="24px"
        />
        <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`" style="width : 100%" />
        <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`">
          <ws-text-field
              v-model="search"
              :placeholder="$t('Search')"
              append-icon="mdi-magnify"
              class="pr-2"
              solo flat
              clearable
              width="200"
          />
        </div>

      </div>

      <div class="v-data-table wsHoverLight v-data-table--dense v-data-table--has-bottom theme--light">
        <div class="v-data-table__wrapper">
        <portal-target name="group_editor_header" tag="table"></portal-target>
      </div>
      </div>

    </template>

    <template #default>
        <ws-navigation
            v-model="navigation"
            :items="navigationSelect"
            no-number
            hide-header
            no-vertical-padding
        >
          <template #item.students>
            <template v-if="displayAddSubgroupDialog">
              <h4>{{ $t('wsu_structure.group.students.add_new_subgroup') }}</h4>
              <ws-text-field
                  v-model="subgroupName"
                  :label="$t('Subgroup')"
                  :placeholder="$t('EnterName')"
              />
            </template>
            <template v-else-if="!displayTransferDialog">
              <ws-data-table
                  v-if="students.length > 0"
                  :items="students"
                  :headers="studentsHeaders"
                  :search="search"
                  :row-action="($event) => selectStudent($event.value)"
                  dense
                  footer-portal="group_editor_footer"
              >

                <template #header="{props,on,currentItems}">
                  <portal to="group_editor_header">
                    <wsTableHeader  :props="props" :on="on" >
                      <template #header.select>
                        <ws-check-box
                            class="ml-4"
                            @input="selectAllStudents($event , currentItems)"
                            :semi-selected="studentsSelection.length > 0"
                            :value="studentsSelection.length === students.length"
                        />
                      </template>
                    </wsTableHeader>
                  </portal>
                </template>

                <template #item.select="{item}">
                  <ws-check-box
                      class="ml-4"
                      @prevent="selectStudent(item.value)"
                      :value="studentsSelection.includes(item.value)"
                      preventSelect
                  />
                </template>

                <template #item.text="{item}">
                  <h5 :style="`color : ${wsACCENT}`">{{ item.text }}</h5>
                </template>

                <template #item.subgroup_name="{item}">
                  <h5 class="text-no-wrap mr-4" >{{ item.subgroup_name }}</h5>
                </template>

              </ws-data-table>
              <v-sheet v-else color="transparent" height="350"  class=" d-flex align-center justify-center">
                <div>
                  <div class="d-flex justify-center">
                    <v-icon size="80" :color="wsACCENT">mdi-account-school</v-icon>
                  </div>
                  <h4 class="text-center mt-3" style="max-width: 500px">{{ $t('wsu_structure.group.students.no_students') }}</h4>
                  <div class="d-flex justify-center mt-3">
                    <ws-button
                        v-if="applicants.length > 0"
                        @click="navigation = 'applicants'"
                        label="wsu_structure.group.students.no_group_students"
                        class="mr-3"
                    />
                    <ws-button
                        v-if="freeUsers.length > 0"
                        @click="navigation = 'free_users'"
                        label="wsu_structure.group.students.free_users"
                    />
                    <h5 v-if="applicants.length === 0 && freeUsers.length === 0 "
                        class="text-center font-weight-regular"
                        style="max-width: 500px">
                      {{ $t('wsu_structure.group.students.no_available_users') }}
                    </h5>
                  </div>
                </div>
              </v-sheet>
            </template>
            <div v-else>
              <h4>{{ $t('wsu_structure.group.students.transfer_description') }}</h4>
              <ws-select
                  class="mt-6 mb-6"
                  v-model="transferGroupId"
                  :items="groupsSelect"
                  :label="$t('Group')"
                  :placeholder="$t('wsu_structure.group.choose')"
                  clearable
                  autocomplete
              />
            </div>

          </template>

          <template #item.applicants>
            <ws-data-table
                :items="applicants"
                :headers="applicantsHeaders"
                :search="search"
                :row-action="($event) => selectApplicant($event.value)"
                dense
                footer-portal="group_editor_footer"
            >
              <template #header="{props,on,currentItems}">
                <portal to="group_editor_header">
                <wsTableHeader  :props="props" :on="on" >
                  <template #header.select>
                    <ws-check-box
                        class="pl-4"
                        @input="selectAllApplicants($event,currentItems)"
                        :semi-selected="applicantsSelection.length > 0"
                        :value="applicantsSelection.length === applicants.length"
                    />
                  </template>
                </wsTableHeader>
                </portal>
              </template>

              <template #item.select="{item}">
                <ws-check-box
                    class="pl-4"
                    @prevent="selectApplicant(item.value)"
                    :value="applicantsSelection.includes(item.value)"
                    preventSelect
                />
              </template>

              <template #item.text="{item}">
                <h5 class="pr-4" :style="`color : ${wsACCENT}`">{{ item.text }}</h5>
              </template>

            </ws-data-table>
          </template>

          <template #item.free_users>
            <ws-data-table
                :items="freeUsers"
                :headers="freeUsersHeaders"
                :search="search"
                :row-action="($event) => selectFreeUser($event.value)"
                dense
                footer-portal="group_editor_footer"
            >

              <template #header="{props,on,currentItems}">
                <portal to="group_editor_header">
                <wsTableHeader  :props="props" :on="on" >
                  <template #header.select>
                    <ws-check-box
                        class="pl-4"
                        @input="selectAllFreeUsers($event , currentItems)"
                        :semi-selected="freeUsersSelection.length > 0"
                        :value="freeUsersSelection.length === freeUsers.length"
                    />
                  </template>
                </wsTableHeader>
                </portal>
              </template>

              <template #item.select="{item}">
                <ws-check-box
                    class="pl-4"
                    @prevent="selectFreeUser(item.value)"
                    :value="freeUsersSelection.includes(item.value)"
                    preventSelect
                />
              </template>

              <template #item.text="{item}">
                <h5 class="pr-4" :style="`color : ${wsACCENT}`">{{ item.text }}</h5>
              </template>

            </ws-data-table>
          </template>

        </ws-navigation>
    </template>

    <template #footer>

      <portal-target name="group_editor_footer" tag="table"></portal-target>

      <div class="d-flex justify-space-between align-center pt-3 px-6 pb-6">
        <div v-if="students.length > 0">

          <template v-if="!displayTransferDialog && !displayAddSubgroupDialog">
            <ft-select
                v-if="navigation === 'students' "
                :items="groupActionsSelect"
                :disabled="studentsSelection.length === 0"
            >
              <ws-button
                  :disabled="studentsSelection.length === 0"
                  :click-stop="false"
                  label="GroupActions"
                  icon="mdi-menu-down"
                  outlined
              />
            </ft-select>

          </template>

          <ws-button
              v-else
              @click="displayTransferDialog = false; displayAddSubgroupDialog = false"
              label="Back"
              outlined
          />
        </div>
        <div>

          <ws-button
              v-if="displayAddSubgroupDialog"
              @click="moveToNewNewSubgroup"
              :disabled="enrollButtonDisabledCondition"
              label="Create"
          />
          <ws-button
              v-if="navigation === 'applicants' || navigation === 'free_users'"
              @click="enrollButton"
              :disabled="enrollButtonDisabledCondition"
              :label="navigation === 'applicants' ? 'wsu_structure.group.students.add' : 'wsu_structure.group.students.enroll'"
          />
          <ws-button
              v-if="displayTransferDialog"
              @click="transferStudents"
              :disabled="!transferGroupId"
              label="wsu_structure.group.students.transfer"
          />

        </div>
      </div>

    </template>


  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsGroupStudentsDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    uuid : {
      type : String
    },
    groupsSelect : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      entity : {},
      display : false,
      displayTransferDialog : false,
      displayAddSubgroupDialog : false,
      students : [],
      applicants : [],
      freeUsers : [],
      studentsSelection : [],
      applicantsSelection : [],
      freeUsersSelection : [],
      navigation : 'students',
      search : '',
      transferGroupId : null,
      subgroupName : '',
      subgroupsSelect : []
    }
  },
  computed : {

    groupActionsSelect() {
      let subgroupItems = []
      this.subgroupsSelect.forEach(el => {
        subgroupItems.push({
          text : el.text,
          action : () => (this.moveToSubgroup(el.value))
        })
      })
      return [
        { text : this.$t('wsu_structure.group.students.set_subgroup') , children : [
            ...subgroupItems,
            { text : this.$t('wsu_structure.group.students.no_subgroup') , action : () => this.moveToSubgroup(null) },
            { text : this.$t('wsu_structure.group.students.new_subgroup') , action : this.openAddSubgroup },
          ]
        },
        { text : this.$t('wsu_structure.group.students.remove') , action : this.removeStudents },
        { text : this.$t('wsu_structure.group.students.transfer') , action : this.openTransferStudents }
      ]
    },
    enrollButtonDisabledCondition() {
      if ( this.navigation === 'applicants' ) {
        return this.applicantsSelection.length === 0
      }
      if ( this.navigation === 'free_users' ) {
        return this.freeUsersSelection.length === 0
      }
      return false
    },
    studentsHeaders() {
      return [
        { value : 'select' ,  padding : 2 ,  width : 1 , sortable : false },
        { text : this.$t('HumanName') , value : 'text' },
        { text : this.$t('Subgroup') , value : 'subgroup_name' , width: 10  , align : 'center' }
      ]
    },
    applicantsHeaders() {
      return [
        { value : 'select' ,  padding : 2 ,  width : 1 , sortable : false },
          { text : this.$t('HumanName') , value : 'text' }
      ]
    },
    freeUsersHeaders() {
      return [
        { value : 'select' ,  padding : 2 ,  width : 1 , sortable : false },
          { text : this.$t('HumanName') , value : 'text' }
      ]
    },

    navigationSelect() {
      let items =  [
        { text : `${this.$t('Students')} (${this.students.length})`  , value : 'students'  }
      ]
      if ( this.applicants.length > 0 ) {
        items.push( { text : `${this.$t('wsu_structure.group.students.no_group_students')} (${this.applicants.length})` , value : 'applicants'  })
      }

      if ( this.freeUsers.length > 0 ) {
        items.push({ text : `${this.$t('wsu_structure.group.students.free_users')} (${this.freeUsers.length})` , value : 'free_users'  })
      }

      return items
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    }
  },
  methods : {
    ...mapActions('wsu_study_profiles' , [
      'GET_ACADEMIC_GROUP_STUDENTS_EDITOR',
      'ACADEMIC_GROUP_ENROLL_FREE_USERS',
      'ACADEMIC_GROUP_ENROLL_APPLICANTS',
      'ACADEMIC_GROUP_REMOVE_STUDENTS',
      'MOVE_TO_SUBGROUP'
    ]),

    updateStudentsSubgroups(data) {
      this.studentsSelection.forEach(student => {
        let index = this.students.findIndex(el => el.value === student)
        if ( index !== -1  ) {
          if ( data.no_group) {
            this.students[index].subgroup_id = null
            this.students[index].subgroup_name = null
          } else {
            this.students[index].subgroup_id = data.value
            this.students[index].subgroup_name = data.text
          }
        }
      })
      this.students = this.COPY(this.students)
      this.studentsSelection = []
      this.displayAddSubgroupDialog = false
    },
    async moveToSubgroup(id) {
      let data =  {
        name : this.subgroupName,
        students : this.studentsSelection,
        group_id : this.uuid,
        subgroup_id : id
      }
      let result = await this.MOVE_TO_SUBGROUP(data)
      if ( !result ) {
        return
      }
      this.studentsSelection
      this.updateStudentsSubgroups(result)
    },
    async moveToNewNewSubgroup() {
      let data =  {
        new_subgroup : true,
        name : this.subgroupName,
        students : this.studentsSelection,
        group_id : this.uuid
      }
      let result = await this.MOVE_TO_SUBGROUP(data)
      if ( !result ) {
        return
      }
      this.subgroupsSelect.push(result)
      this.updateStudentsSubgroups(result)
    },
    openAddSubgroup() {
      this.subgroupName = ''
      this.displayAddSubgroupDialog = true
    },
    openTransferStudents() {
      this.transferGroupId = null
      this.displayTransferDialog = true
    },

    async enrollUsersToGroup() {
      let data = {
        users : this.freeUsersSelection,
        group_id : this.uuid
      }
      let result = await this.ACADEMIC_GROUP_ENROLL_FREE_USERS(data)
      if ( !result ) {
        return
      }
      this.freeUsers = this.freeUsers.filter(el => !this.freeUsersSelection.includes(el.value) )
      result.students.forEach(student => {
        this.students.push(student)
      })
      this.freeUsersSelection = []
      if ( this.freeUsers.length === 0 ) {
        this.navigation = 'students'
      }
      this.notify(this.$t('wsu_structure.group.students.free_users_added_to_group') , 'success')
      this.$emit('students-count-update' , result.students.length )
    },
    async enrollApplicantsToGroup() {
      let data = {
        students : this.applicantsSelection,
        group_id : this.uuid
      }
      let result = await this.ACADEMIC_GROUP_ENROLL_APPLICANTS(data)
      if ( !result ) {
        return
      }
      this.applicants = this.applicants.filter(el => !this.applicantsSelection.includes(el.value) )
      result.students.forEach(student => {
        this.students.push(student)
      })
      this.applicantsSelection = []
      if ( this.applicants.length === 0 ) {
        this.navigation = 'students'
      }
      this.notify(this.$t('wsu_structure.group.students.applicants_added_to_group') , 'success')
      this.$emit('students-count-update' , result.students.length )
    },
    async removeStudents() {
      let data = {
        students : this.studentsSelection,
        group_id : this.uuid
      }
      let result = await this.ACADEMIC_GROUP_REMOVE_STUDENTS(data)
      if ( !result ) {
        return
      }
      this.students = this.students.filter(el => !this.studentsSelection.includes(el.value) )
      result.students.forEach(student => {
        this.applicants.push(student)
      })
      this.studentsSelection = []
      this.notify(this.$t('wsu_structure.group.students.students_removed'))
      this.$emit('students-count-update' , -result.students.length )
    },
    async transferStudents() {
      let data = {
        students : this.studentsSelection,
        group_id : this.transferGroupId
      }
      let result = await this.ACADEMIC_GROUP_ENROLL_APPLICANTS(data)
      if ( !result ) {
        return
      }
      this.students = this.students.filter(el => !this.studentsSelection.includes(el.value) )
      result.students.forEach(student => {
        this.applicants.push(student)
      })
      this.studentsSelection = []
      this.notify(this.$t('wsu_structure.group.students.students_removed'))
      this.$emit('students-count-update' , -result.students.length )
    },

    enrollButton() {
      if ( this.navigation === 'applicants' ) {
        return this.enrollApplicantsToGroup()
      }
      if ( this.navigation === 'free_users' ) {
        return this.enrollUsersToGroup()
      }
    },

    // Technical

    selectAllStudents(value , currentItems) {
      if ( !value ) {
        this.studentsSelection = []
      } else {
        if ( !this.search ) {
          this.studentsSelection = this.students.map(el => el.value)
        } else {
          this.studentsSelection = currentItems.map(el=> el.value)
        }
      }
    },
    selectAllApplicants(value , currentItems) {
      if ( !value ) {
        this.applicantsSelection = []
      } else {
        if ( !this.search ) {
          this.applicantsSelection = this.applicants.map(el => el.value)
        } else {
          this.applicantsSelection = currentItems.map(el=> el.value)
        }
      }
    },
    selectAllFreeUsers(value , currentItems) {
      if ( !value ) {
        this.freeUsersSelection = []
      } else {
        if ( !this.search ) {
          this.freeUsersSelection = this.freeUsers.map(el=> el.value)
        } else {
          this.freeUsersSelection = currentItems.map(el=> el.value)
        }

      }
    },

    selectFreeUser( uuid ) {
      if ( this.freeUsersSelection.includes(uuid) ) {
        this.freeUsersSelection = this.freeUsersSelection.filter(el => el !== uuid)
      } else {
        this.freeUsersSelection.push(uuid)
      }
    },

    selectStudent( uuid ) {
      if ( this.studentsSelection.includes(uuid) ) {
        this.studentsSelection = this.studentsSelection.filter(el => el !== uuid)
      } else {
        this.studentsSelection.push(uuid)
      }
    },

    selectApplicant( uuid ) {
      if ( this.applicantsSelection.includes(uuid) ) {
        this.applicantsSelection = this.applicantsSelection.filter(el => el !== uuid)
      } else {
        this.applicantsSelection.push(uuid)
      }
    },

    async initPage() {
      let result = await this.GET_ACADEMIC_GROUP_STUDENTS_EDITOR(this.uuid)
      if ( !result ) {
        return
      }
      this.entity = result.group
      this.students = result.students
      this.applicants = result.applicants
      this.freeUsers = result.free_users
      this.subgroupsSelect = result.subgroups
    }
  },
  mounted() {

    if ( this.value ) {
      this.display = this.value
    }
    this.initPage()

  }
}
</script>

<style scoped>

</style>