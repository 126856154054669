<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Teachers')"
      :subtitle="$t('Structure')"
      icon="mdi-account-tie"
      :header-action="openNewTeacher"
      :header-action-text="$t('wsu_structure.department.teachers.add')"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu_structure.department.teachers.no_content')"
      :no-content-button-action="openNewTeacher"
      :no-content-button-text="$t('wsu_structure.department.teachers.add')"
      :header-dropdown-actions="dropdownActionsSelect"
      fill-height

  >

    <template #default >
      <ws-data-table
          :items="items"
          :row-action="openViewTeacher"
          :headers="headers"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          class="mt-5"
      >
        <template #item.name="{item}">
          <ws-user-data :item="item" />
        </template>

        <template #item.disciplines_count="{item}">
          <h5 class="text-center"> {{ item.disciplines_count }} </h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <!--  View teacher -->
      <ws-dialog
          v-if="displayTeacherDialog"
          v-model="displayTeacherDialog"
          :title="selectedTeacher.name"
          :hide-save="!displayNewDiscipline"
          :disable-save="!newDiscipline.discipline_id || !newDiscipline.degree_id "
          :cancel-text="$t(displayNewDiscipline ? 'Back' : 'Close')"
          @save="addTeacherDiscipline"
          :save-text="$t('wsu_structure.discipline.add')"
          width="750"
          height="90%"
          max-height="642px"
          not-centered
          cancel-action
          @cancel="teacherDialogCancelAction"
      >

        <!--   Add Discipline-->
        <v-sheet v-if="!displayNewDiscipline && selectedTeacher.disciplines.length > 0 && disciplinesSelectFiltered.length > 0" :color="wsLIGHTCARD" class="wsRoundedLight pa-2">
          <h5 @click="openAddNewDiscipline" class="pointer" :style="`color : ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-plus</v-icon>
            {{ $t('wsu_structure.discipline.add') }}
          </h5>
        </v-sheet>

        <v-sheet v-if="displayNewDiscipline" :color="wsLIGHTCARD" :style="`border : 1px solid ${wsBACKGROUND} !important`" class="mt-5 pa-5 wsRoundedLight" >
          <h4 class="mb-5"> {{ $t('wsu_structure.discipline.add') }}</h4>



          <ws-select
              v-model="newDiscipline.discipline_id"
              :label="$t('Discipline')"
              :placeholder="$t('wsu_structure.discipline.choose')"
              :items="disciplinesSelectFiltered"
              autocomplete
              clearable
          />

          <ws-select
              class="mt-6"
              v-model="newDiscipline.degree_id"
              :label="$t('Degree')"
              :items="degreesSelectFiltered"
              :disabled="degreesSelectFiltered.length === 0"
              :placeholder="$t('wsu_structure.degree.choose')"
          />


<!--          <ws-switch-->
<!--              v-model="newDiscipline.is_auto"-->
<!--              placeholder="isAuto"-->
<!--              tooltip="Aut hours tooltip"-->
<!--          />-->
        </v-sheet>

        <!-- Teachers Disciplines -->
        <ws-data-table
            v-else-if="selectedTeacher.disciplines.length > 0"
            class="mt-5"
            :items="selectedTeacher.disciplines"
            :headers="disciplinesHeaders"
            dense
            disable-pagination
            no-footer
        >

          <template #item.discipline_name="{item}">
            <h5 :style="`color : ${wsACCENT}`">{{ item.discipline_name }}</h5>
          </template>

          <template #item.degree_name="{item}">
            <h5 >{{ item.degree_name }}</h5>
          </template>

          <template #item.action="{item , hover}">
            <v-sheet color="transparent" width="30px">
              <v-btn small v-if="hover" @click.stop="deleteTeacherDiscipline(item)" icon :color="wsACCENT">
                <v-icon size="20">mdi-delete-outline</v-icon>
              </v-btn>
            </v-sheet>

          </template>

        </ws-data-table>
        <!-- No Disciplines placeholder -->
        <div v-else class="fill-height d-flex align-center justify-center">
          <div>
            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsDARKLIGHT">mdi-school-outline</v-icon>
            </div>
            <h3 class="text-center">{{ $t('wsu.teachers.disciplines.no_content.title') }}</h3>
            <h4 class="text-center font-weight-regular">{{ $t('wsu.teachers.disciplines.no_content.text') }}</h4>
            <div class="d-flex justify-center mt-5">
              <ws-button @click="openAddNewDiscipline" label="wsu_structure.discipline.add" />
            </div>
          </div>

        </div>

      </ws-dialog>

      <!--  Add new teacher -->
      <ws-dialog
          v-model="displayDialog"
          :title="$t('wsu.teachers.add.title')"
          @save="addNewTeacher"
          @delete="deleteFaculty"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          show-delete
      >

        <ws-new-user-input
            v-model="entityData"
            @set-validate="validateUser = $event"
        />


      </ws-dialog>
      <!--  Add teacher from managers List  -->
      <ws-dialog
          v-if="displaySelectTeacherDialog"
          v-model="displaySelectTeacherDialog"
          @save="addSelectedTeachers"
          :title="$t('wsu.teachers.add.select')"
          :save-text="$t('Choose')"
          width="700"
          max-height="600px"
          height="90%"
          not-centered
      >
        <template #header-after>
          <div class="d-flex">
            <ws-navigation-header
                :items="navigationSelect"
                value="teachers"
                no-number
                padding="24px"
            >
            </ws-navigation-header>
            <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`" style="width : 100%" />
            <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`">
              <ws-text-field
                  v-model="search"
                  :placeholder="$t('Search')"
                  append-icon="mdi-magnify"
                  class="pr-2"
                  style="margin-bottom: -10px"
                  solo flat
                  clearable
                  width="200"
              />
            </div>
          </div>
        </template>

        <ws-data-table
            class="mt-5"
            v-if="managersSelect.length > 0"
            :items="managersSelectFiltered"
            :headers="teachersHeaders"
            :search="search"
            :row-action="($event) => selectTeacher($event.uuid)"
            dense
        >
          <template #item.select="{item}">
            <ws-check-box
                @prevent="selectTeacher(item.uuid)"
                :value="teachersSelection.includes(item.uuid)"
                preventSelect
            />
          </template>

          <template #item.name="{item}">
            <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
            <h5 class="font-weight-regular">{{ item.email }}</h5>
          </template>

        </ws-data-table>

      </ws-dialog>

      <!--  Import Disciplines -->
      <ws-file-import-dialog
          v-if="displayImportDialog"
          @success="initPage"
          v-model="displayImportDialog"
          entity="teachers"
          :body="importRequestData"
      />


      <!--  Delete Teacher -->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteFaculty"
          :title="$t('wsu_structure.faculty.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

    </template>

  </sub-page>
</template>

<script>
import wsFileImportDialog from "@/components/UI/wsFileImportDialog";
import wsNewUserInput from "@/components/UI/wsNewUserInput";
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  components : {
    wsNewUserInput,
    wsFileImportDialog,
  },
  data() {
    return {
      items : [],
      managersSelect : [],
      departmentsSelect : [],
      specialtiesSelect : [],
      specializationsSelect : [],
      degreesSelect : [],
      displayDialog : false,
      entityData : {},
      selectedItem : {},
      displayDeleteDialog : false,
      editEntity : false,

      displaySelectTeacherDialog : false,
      displayImportDialog : false,
      validateUser : () => {},
      search : '',
      teachersSelection : [],
      displayTeacherDialog : false,
      selectedTeacher : { disciplines : []},

      disciplinesSelect : [],
      newDiscipline : {},
      displayNewDiscipline : false

    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),

    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Disciplines') , value : 'disciplines_count' , width : 30 , align : 'center'},
      ]
    },

    disciplinesSelectFiltered() {
      let items = this.disciplinesSelect

      if ( this.selectedTeacher.disciplines ) {
        let teacherDisciplinesObject = {}
        this.selectedTeacher.disciplines.forEach(el => {
          if ( !teacherDisciplinesObject[el.discipline_id] ) {
            teacherDisciplinesObject[el.discipline_id] = []
          }
          teacherDisciplinesObject[el.discipline_id].push(el.degree_id)
        })
        items = items.filter(el => {
          if ( teacherDisciplinesObject[el.value] && teacherDisciplinesObject[el.value].length === el.degrees_id.length  ) {
            return false
          }

          return true
        })
      }

      return items
    },
    degreesSelectFiltered() {
      let items = this.degreesSelect
      if ( !this.newDiscipline.discipline_id ) {
        return []
      }
      let discipline = this.disciplinesSelect.find ( el => el.value === this.newDiscipline.discipline_id)
      if ( discipline ) {
        items = items.filter( el =>  discipline.degrees_id.includes(el.value) )
      }
      if ( this.selectedTeacher.disciplines ) {
        let teachersDisciplinesDegrees = this.selectedTeacher.disciplines.filter(el => el.discipline_id === this.newDiscipline.discipline_id).map(el => el.degree_id)
        if ( teachersDisciplinesDegrees.length > 0 ) {
          items = items.filter(el => !teachersDisciplinesDegrees.includes(el.value))
        }
      }
      return items
    },
    teacherDialogCancelAction() {
      if ( this.displayNewDiscipline ) {
        return () => this.displayNewDiscipline = false
      } else {
        return  () => this.displayTeacherDialog = false
      }
    },
    disciplinesHeaders() {
      return [
        { text : this.$t('Discipline') , value : 'discipline_name' },
        { text : this.$t('Degree') , value : 'degree_name' },
        { value : 'action' , width : 10 , sortable : false }
      ]
    },
    importRequestData() {
      return {
        department_id : this.$store.state.wsu_structure.selectedEntityId
      }
    },
    navigationSelect() {
      return [
        { text : `${this.$t('UniversityStuff')} (${this.managersSelectFiltered.length})`  , value : 'teachers'  }
      ]
    },
    managersSelectFiltered() {
      return this.managersSelect.filter(el => !this.currentManagersIds.includes(el.uuid))
    },
    currentManagersIds() {
      return this.items.map(el => el.manager_id)
    },

    teachersHeaders() {
      return [
        { value : 'select' ,  padding : 2 ,  width : 1 , sortable : false },
        { text : this.$t('HumanName') , value : 'name' },
        { text : 'teachers' , value : 'email' , align : 'd-none'  }
      ]
    },

    dropdownActionsSelect() {
      return [
        { text : this.$t('wsu.teachers.add.add_new') , value : 'add' , action : this.openNewTeacher },
        { text : this.$t('wsu.teachers.add.select') , value : 'select' , action : this.openSelectTeacher },
        { text : this.$t('ImportFromFile') , value : 'import' , action : this.openImport },
      ]
    },
    
    specializationsSelectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      if ( !this.entityData.specialty_id) {
        return []
      }

      items = items.filter(el => el.specialty_id === this.entityData.specialty_id )


      return items
    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditFaculty() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
    specializationsSlectFiltered() {
      let items = this.specializationsSelect
      if ( !items ) {
        return []
      }

      return items
    },

  },
  methods : {
    ...mapActions('wsu_teacher_profiles' , [
        'GET_DEPARTMENT_TEACHERS' ,
        'ADD_NEW_TEACHER_PROFILE' ,
        'ADD_SELECTED_TEACHER_PROFILES' ,
        'ADD_TEACHER_DISCIPLINE' ,
        'DELETE_TEACHER_DISCIPLINE' ,
    ]),

    openAddNewDiscipline() {
      this.displayNewDiscipline = true
      this.newDiscipline = {}
    },
    async deleteTeacherDiscipline(item) {
      let result = await this.DELETE_TEACHER_DISCIPLINE(item.uuid)
      if ( !result ) {
        this.ALERT()
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedTeacher.uuid)
      let disciplineIndex = this.items[index].disciplines.findIndex(el => el.uuid === item.uuid)
      this.selectedTeacher.disciplines.splice(disciplineIndex , 1)
      this.items[index].disciplines.splice(disciplineIndex , 1)
      this.items[index].disciplines_count--
      this.items = this.COPY(this.items)
    },

    async addTeacherDiscipline() {
      let data = this.COPY(this.newDiscipline)
      data.teacher_id = this.selectedTeacher.uuid
      let result  = await this.ADD_TEACHER_DISCIPLINE(data)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedTeacher.uuid )
      this.items[index].disciplines.push(result)
      this.items = this.COPY(this.items)
      this.selectedTeacher.disciplines.push(result)
      this.items[index].disciplines_count++
      this.displayNewDiscipline = false
    },
    openViewTeacher(item) {
      this.selectedTeacher = this.COPY(item)
      this.displayTeacherDialog = true
    },
    async addSelectedTeachers() {
      let data = {
        department_id : this.$store.state.wsu_structure.selectedEntityId,
        managers : this.teachersSelection
      }
      let result = await this.ADD_SELECTED_TEACHER_PROFILES(data)
      if ( !result || result === true ) {
        return
      }
      this.items.unshift(...result)
      this.displaySelectTeacherDialog = false

    },
    selectTeacher( uuid ) {
      if ( this.teachersSelection.includes(uuid) ) {
        this.teachersSelection = this.teachersSelection.filter(el => el !== uuid)
      } else {
        this.teachersSelection.push(uuid)
      }
    },


    // AJAX
    async addNewTeacher() {

      if ( !this.validateUser() ) {
        return
      }
      this.entityData.department_id = this.$store.state.wsu_structure.selectedEntityId
      let result = await this.ADD_NEW_TEACHER_PROFILE(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }

      this.notify(this.$t('wsu_structure.specialty.created') , 'success')

      if ( !this.editEntity ) {
        this.items.unshift(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
      }

      this.displayDialog = false
    },
    async deleteFaculty() {
      let result = await this.DELETE_FACULTY(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu_structure.faculty.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    // Methods
    openEditFaculty() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },
    openNewTeacher() {
      this.editEntity = false
      this.entityData = {}
      this.displayDialog = true
    },
    openSelectTeacher() {
      this.displaySelectTeacherDialog = true
    },
    openImport() {
      this.displayImportDialog = true
    },
    // Technical
    async initPage() {
      let result = await this.GET_DEPARTMENT_TEACHERS(this.$store.state.wsu_structure.selectedEntityId)
      this.items = result.teachers || []
      this.items.reverse()
      this.managersSelect = result.managers || []
      this.disciplinesSelect = result.disciplines
      this.degreesSelect = result.degrees

      // this.departmentsSelect = result.departments_select || []
      // this.specialtiesSelect = result.specialties_select || []
      // this.specializationsSelect = result.specializations_select || []


    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>