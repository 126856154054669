<template>
    <description-specialty
        v-if="entityType === 'specialty'"
        :uuid="uuid"
    />
</template>

<script>
import descriptionSpecialty from "@modules/wsu_structure/components/description/descriptionSpecialty.vue";
export default {
  name: "description",
  components: {
    descriptionSpecialty
  },
  props : {
    entityType : {
      type : String
    },
    uuid : {
      type : String
    }
  }
}
</script>

<style scoped>

</style>